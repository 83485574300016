import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Tabla from "../../components/Tabla";
import Header from "../../components/Header";
import Regresar from "../../components/Back";

import tokenAuth from "../../../config/token";
import clienteAxios from "../../../config/axios";

import AlertasContext from "../../../context/alertas/alertasContext";
import AuthContext from "../../../context/autenticacion/authContext";

const ConsultarCFDI = () => {
  const history = useNavigate();
  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, usuarioAutenticado, cerrarSesion } =
    authContext;

  // En caso de que el usuario se haya autenticado o alguno de sus datos sea incorrecto
  useEffect(() => {
    usuarioAutenticado();
    if (!autenticado) {
      mostrarAlerta('Caduco la sesión', "alerta-error");
      setTimeout(() => {
        history("/");
      }, 1000);
    }
  }, []);

  return (
    <div className="contenedor-menu">
      <div className="container mx-auto bg-white">
        <header id="header" className="bg-white font-bold">
          <Regresar />
          <p className="text-center text-2xl p-5"
          >Consultar CFDIs</p>
        </header>
        <form id="menu-form" className="py-10 mx-auto">
          <Tabla tabla />
        </form>
      </div>
    </div>
  );
};

export default ConsultarCFDI;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import Menu from "./components/Menu/Menu";
import CargarCFDI from "./components/Menu/Frames/CargarCFDI";
import ConsultarCFDI from "./components/Menu/Frames/ConsultarCFDI";

import UserState from "./context/User/userState";
import AuthState from "./context/autenticacion/authState";
import AlertaState from "./context/alertas/alertasState";
import tokenAuth from "./config/token";
import RutaPrivada from "./components/rutas/RutaPrivada";

// Revisar si ya existe algún token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL);
  }, []);

  return (
    <UserState>
      <AlertaState>
        <AuthState>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/" element={<RutaPrivada />}>
                <Route path="dashboard" element={<Menu />} />
                <Route path="cargar-cfdi" element={<CargarCFDI />} />
                <Route path="consultar-cfdi" element={<ConsultarCFDI />} />
              </Route>
            </Routes>
          </Router>
        </AuthState>
      </AlertaState>
    </UserState>
  );
}

export default App;

import React, { useState, useContext, useEffect } from "react";
import AlertasContext from "../../context/alertas/alertasContext";
import AuthContext from "../../context/autenticacion/authContext";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";

const Login = (props) => {
  const navigate = useNavigate();

  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, iniciarSesion } = authContext;

  // Estado para controlar el cargando
  const [cargando, setCargando] = useState(false);
  // Referencia para el recaptcha
  const recaptchaRef = React.createRef();

  // En caso de que el usuario se haya autenticado o alguno de sus datos sea incorrecto
  useEffect(() => {
    if (autenticado) {
      navigate('/dashboard');
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
    setCargando(false); // Esconder el loading después de manejar el mensaje
  }, [mensaje, autenticado, navigate, mostrarAlerta]);

  // State para iniciar sesión
  const [usuario, guardarUsuario] = useState({
    user: "",
    password: "",
  });

  // Extraer usuario
  const { user, password } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const token = recaptchaRef.current.getValue();
    if (!token) {
      mostrarAlerta("Por favor, verifica que no eres un robot", "alerta-error");
      return;
    }
    setCargando(true); // Mostrar loading

    // Validamos que no vayan solos
    if (user.trim() === "" || password.trim() === "") {
      mostrarAlerta("Todos los campos son obligatorios", "alerta-error");
      setCargando(false); // Esconder loading si hay un error
      return;
    }
    if (user.trim().length < 12 || user.trim().length > 13) {
      mostrarAlerta("RFC invalido", "alerta-error");
      setCargando(false); // Esconder loading si hay un error
      return;
    }
    iniciarSesion({ rfc: user, password });
  };
  return (
    <div className="container mx-auto mt-20 bg-white">
      {alerta ? (
        <div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>
      ) : null}
      <header id="header" className="bg-white p-3 font-bold">
        PORTAL DE PROVEEDORES
      </header>

      <form
        id="user-form"
        className="py-10 px-5 max-w-lg mx-auto"
        onSubmit={onSubmit}
      >
        <div className="mb-10">
          <div className="input-user">
            <img src="img/user_24px.png" className="icon" alt="user_image" />
            <input
              type="text"
              placeholder="RFC"
              id="user"
              name="user"
              value={user}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="mb-10">
          <div className="input-password">
            <img src="img/lock_24px.png" className="icon" alt="pass_image" />
            <input
              type="password"
              placeholder="Contraseña"
              id="password"
              name="password"
              value={password}
              onChange={onChange}
            />
          </div>
        </div>
        <ReCAPTCHA className="mb-10"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={recaptchaRef} />
        <div className="mb-10 text-center">
          <button
            type="submit"
            className="button"
            disabled={cargando}
          >
            {cargando ? (
              <div className="flex items-center">
                <CircularProgress />
                Cargando...
              </div>
            ) : (
              "Iniciar Sesión"
            )}
          </button>
        </div>
        <p>
          Si no cuentas con tu contraseña, solicitala a{" "}
          <a href="mailto:cuentasporpagar@pinosaltos.com.mx?body=Buen día, solicitando mi contraseña para el acceso a portal del proveedores">
            cuentasporpagar@pinosaltos.com.mx
          </a>
        </p>
        
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, esES } from "@mui/x-data-grid";
import { esES as coreEsES } from "@mui/material/locale";

import clienteAxios from "../../config/axios";
import UserContext from "../../context/User/userContext";
import AlertasContext from "../../context/alertas/alertasContext";

import moment from "moment";

moment.locale("es");
const format = "DD-MMM-YYYY HH:mm:ss"

const currencyFormatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});
const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },

    typography: {
      fontSize: 20,
    },
  },
  esES,
  coreEsES
);
function toBase64(arr) {
  //arr = new Uint8Array(arr) if it's an ArrayBuffer
  return btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ""));
}
const columns = [
  {
    field: "estado",
    headerName: "Estado",
    minWidth: 50,
    flex: .5,
    renderCell: (params) => {
      return (
        <div>
          <img
            src={
              params.row.estado.data != ""
                ? `data:image/png;base64,${toBase64(params.row.estado.data)}`
                : ""
            }
            alt=""
          />
          {params.row.username}
        </div>
      );
    },
    
  },
  { field: "serie", headerName: "Serie-Folio", minWidth: 60, flex: 0.5 },
  {
    field: "fechaexpedicion",
    headerName: "Fecha Expedición",
    type: "dateTime",
    minWidth: 130,
    flex: 1,
    valueGetter: ({ value }) => value && new Date(value)
  },
  {
    field: "fecharecepcion",
    headerName: "Fecha Recepción",
    type: "dateTime",
    minWidth: 130,
    flex: 1,
    valueGetter: ({ value }) => value && new Date(value)
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "notas",
    headerName: "Notas de Crédito",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "pagosefectuados",
    headerName: "Pagos Efectuados a la fecha",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "saldo",
    headerName: "Saldo",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.total-params.row.notas-params.row.pagosefectuados }`;
    },
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "pagosemitidos",
    headerName: "CFDI Pagos Emitidos",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
];

export default function DataTable() {
  // Extrar proyectos de state inicial
  const userContext = useContext(UserContext);
  const { mensaje, cfdi, obtenerCFDIS } = userContext;

  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    // si hay un error
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
    obtenerCFDIS();

    // eslint-disable-next-line
  }, [mensaje]);
  return (
    <div style={{  width: "100%" }}>
      <ThemeProvider
        theme={theme}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      >
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={cfdi}
          rowHeight={35}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          disableSelectionOnClick
          // disableColumnFilter // or filterable: false in each column
           autoHeight 
          // disableColumnMenu
        />
      </ThemeProvider>
    </div>
  );
}
